<template >
  <Modal style="z-index: 10000;" class="modal-image-enlargement" noPadding @close="$emit('close')">
    <img class="modal-image-enlargement__image" :src="image" alt />
  </Modal>
</template>

<script>
import Modal from "./Modal";

export default {
  components: {
    Modal
  },

  props: {
    image: {
      type: String
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.modal-image-enlargement {
  z-index: 100;

  &__image {
    height: 80vh;

    @media (max-width: 575px) {
      height: auto;
      width: 100%;
    }
  }
}
</style>