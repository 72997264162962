<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <!-- <pre>{{ form }}</pre> -->
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6">
          <CInput v-model="form[`name`]" :label="`Name`" :placeholder="`Name`" />
        </CCol>

        <CCol sm="6"></CCol>

        <CCol sm="6" v-for="lang in languages" :key="`name_${lang}`">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
      </CRow>

      <!-- <CRow v-for="lang in languages" :key="`description_${lang}`">
        <CCol sm="12">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
      </CRow>-->

      <CRow>
        <CCol sm="6">
          <CInput v-model="form.product_id" label="Product id" placeholder="Product id" />
        </CCol>

        <CCol sm="6">
          <CInput
            v-model="form.count_download"
            label="Count download"
            placeholder="Count download"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="6">
          <CInput v-model="form.created_at" label="Created At" placeholder="Created At" />
        </CCol>

        <CCol sm="6">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show"
            :checked="form.show"
            @update:checked="value => (form.show = value)"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            :value="form.image1 && form.image1.file"
            name="image"
            :image="form.image1 && form.image1.url ? form.image1.url : form.image1"
            @input="value => handleChangeFile({file: value, key: 'image1'})"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            :value="form.image2 && form.image2.file"
            name="image"
            :image="form.image2 && form.image2.url ? form.image2.url : form.image2"
            @input="value => handleChangeFile({file: value, key: 'image2'})"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            :value="form.image3 && form.image3.file"
            name="image"
            :image="form.image3 && form.image3.url ? form.image3.url : form.image3"
            @input="value => handleChangeFile({file: value, key: 'image3'})"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <ModalPresets
            :isShow="isShow"
            :data="form.presets"
            @change="value => (form.presets = value)"
            @delete="deletePreset"
          />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

import ModalPresets from "./ModalPresets";
import FileLoader from "@/components/root/FileLoader";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    ModalPresets,
    FileLoader
  },

  data: () => ({
    form: {
      name: null,
      product_id: null,
      count_download: 0,
      presets: [],
      show: true,
      image1: null,
      image2: null,
      image3: null,
      created_at: null
    },

    languages: ["en", "ru", "fr", "es", "de", "it"]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data
          ? "Edit Luna collection"
          : "Add Luna collection";
      }
    },

    createdAt() {
      // return this.form[`created_at`];
      // return moment(this.form[`created_at`])
      //   .subtract("hours", 3)
      //   .format("YYYY-MM-DD hh:mm:ss");
    }
  },

  created() {
    // for (let lang of this.languages) {
    //   this.form[`name_${lang}`] = null;
    // }

    for (let lang of this.languages) {
      this.form[`description_${lang}`] = null;
    }
  },

  methods: {
    handleChangeFile({file, key}) {
      console.log("handleChangeFile", {file, key});

      // if (!key) key = this.activeImage.key;

      this.form[key] = {
        type: "upload",
        file,
        url: URL.createObjectURL(file)
      };

      this.$emit("change", this.form);
    },

    deletePreset(index) {
      console.log(index);

      if (this.form.presets[index] && this.form.presets[index].id)
        this.handlerDeletePreset({
          id: this.form.presets[index].id,
          cb: () => {
            this.form.presets.splice(index, 1);
          }
        });
      else this.form.presets.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
