<template>
  <transition name="fade">
    <div :class="['app-modal', mods]" ref="appModal">
      <div class="app-modal__wrapper" @click.self="closing && close()">
        <div class="container m-auto">
          <div class="row">
            <div
              class="col-12 d-flex justify-content-center align-items-center"
              @click.self="closing && close()"
            >
              <div class="app-modal__content">
                <div class="app-modal__icon-clouse" v-if="isShowClose">
                  <button type="button" aria-label="Close" class="close" @click="close">×</button>
                  <!-- <font-awesome-icon icon="times"/> -->
                </div>

                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

// import { MIXINS_BODY_NO_SCROLL } from "@/mixins/MixinsBodyNoScroll";

export default {
  name: "app-modal",

  // mixins: [MIXINS_BODY_NO_SCROLL],

  props: {
    scrollDisabled: {
      type: Boolean,
      default: true
    },

    closing: {
      type: Boolean,
      default: true
    },

    isShowModal: {
      type: Boolean,
      default: false
    },

    isShowClose: {
      type: Boolean,
      default: true
    },

    noPadding: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tPref: "global"
  }),

  computed: {
    ...mapGetters({
      activeSidebarName: "activeSidebarName"
    }),

    mods() {
      return {
        "app-modal_no-padding": this.noPadding
      };
    }
  },

  // watch: {
  //   isShowModal(next) {
  //     console.log(next);
  //     if (next) {
  //       this.hideScroll();
  //     } else {
  //       this.showScroll();
  //     }
  //   }
  // },

  methods: {
    close() {
      this.$emit("close");
    }
  },

  mounted() {
    if (this.scrollDisabled) {
      this.hideScroll();
    }
  },

  destroyed() {
    if (this.scrollDisabled) {
      this.showScroll();
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes modal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.app-modal {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.6);

  backdrop-filter: blur(8px);

  z-index: 99;
  cursor: auto;

  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  animation: modal 0.4s;

  &_no-padding {
    .app-modal {
      &__content {
        background: transparent;
        border: none;
        // padding: 0;
      }
    }
  }

  // visibility: hidden;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }

  & * {
    transform: translate3d(0, 0, 0);
  }

  @media (max-width: 575px) {
    .container {
      width: 100%;

      margin: 0;
    }
  }

  &--full {
    .container {
      width: 100%;
      height: auto;
    }
  }

  &--noPadding {
    .app-modal {
      &__body {
        padding: 0;
      }
    }
  }

  &--isLightBackground {
    background: rgba(27, 43, 72, 0.4);
  }

  &--isFull {
    .app-modal {
      &__content {
        width: 100%;
      }
    }
  }

  &--isMobile {
    width: 100%;

    padding: 0 !important;

    background: #fff;

    .app-modal {
      &__header {
        // background-color: $color-primary;

        span {
          margin: 0 auto 0 0;
        }
      }

      &__close-btn {
        display: none;
      }

      &__content {
        margin: auto 0;

        box-shadow: none;
        border-radius: 0;
      }

      &__title {
        margin: 0;
      }
    }
  }

  &--fullScreen {
    // overflow-y: hidden;
    background: #fff;

    .container {
      max-width: 100%;
      padding: 0;
      margin: 0 !important;

      // min-height: 100%;

      @media (max-width: 575px) {
        padding: 0 15px;
      }
    }

    // .row,
    // .col-12 {
    //   // min-height: 100%;
    // }

    .app-modal {
      &__content {
        width: 100%;
        // min-height: 100%;
        // height: 90vh;
        margin: 0;
        border-radius: 0;

        box-shadow: none;

        // overflow-y: scroll;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    // @media (max-width: 767.98px) {
    padding: 3rem 0.25rem;
    // }

    // -webkit-overflow-scrolling: touch !important;
  }

  &__content {
    width: 40.6rem;
    padding: 2.8rem 1.85rem;
    margin-bottom: 3rem;

    display: flex;
    flex-direction: column;
    // margin: 45px 0;
    // width: 100%;

    border-radius: 0.5rem;
    filter: drop-shadow(0 0 1.2rem #14141a);
    background-color: #252530;
    border: 1px solid #5f5f5f;

    overflow: hidden;

    @media (max-width: 767.98px) {
      padding: 0.8rem 0.5rem;
    }
  }

  &__header {
    margin-bottom: 0.7rem;

    width: 100%;

    display: flex;
    justify-content: flex-end;
  }

  &__icon-clouse {
    position: absolute;
    right: 0.9rem;
    top: 0.9rem;
    padding: 0.2rem;
    // margin-right: auto;

    font-size: 1.1rem;

    cursor: pointer;

    z-index: 100;
  }
}

@media (max-width: 767px) {
  .app-modal {
    .col-12 {
      padding: 0;
    }
  }
}
</style>
